import React, { useState, useEffect } from "react";
import axios from "axios";

const CompDistributorManufacturer = ({ companyId, companyType }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!companyId || !companyType) return;

    let endpoint = "";
    if (companyType === "Supplier") {
      endpoint = `${process.env.REACT_APP_API_URL}/suppliers/${companyId}/manufacturers`;
    } else if (companyType === "Manufacturer") {
      endpoint = `${process.env.REACT_APP_API_URL}/manufacturers/${companyId}/suppliers`;
    }

    axios
      .get(endpoint)
      .then((res) => {
        console.log("Response data:", res.data);
        
        // בדיקה מה באמת מגיע מהשרת
        if (Array.isArray(res.data)) {
          setItems(res.data);
        } 
        else if (res.data && Array.isArray(res.data.items)) {
          // לדוגמה, אם הגענו למבנה: { "items": [...] }
          setItems(res.data.items);
        }
        else {
          setItems([]);
        }
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        setItems([]);
      });
  }, [companyId, companyType]);

  return (
    <div>
      <h2>Where to Buy</h2>
      <ul>
        {Array.isArray(items) &&
          items.map((item) => (
            <li key={item.id}>{item.name_en}</li>
          ))}
      </ul>
    </div>
  );
};


export default CompDistributorManufacturer;
