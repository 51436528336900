import React, { useState } from 'react';
import axios from 'axios';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import '../styles/Contact.css';

function ContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Company: '',
    Position: '',
    Subject: '',
    Message: ''
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!emailRegex.test(formData.Email)) {
      setErrorMsg("Please enter a valid email address.");
      return false;
    }
    if (formData.Message.trim().length < 10) {
      setErrorMsg("Message must be at least 10 characters long.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    if (!validateForm()) return;
    try {
      setLoading(true);
      const token = await executeRecaptcha("contactForm");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contactus`, {
        ...formData,
        captcha: token
      });
      alert(response.data.message);
      setFormData({
        Name: '',
        Email: '',
        Phone: '',
        Company: '',
        Position: '',
        Subject: '',
        Message: ''
      });
    } catch (error) {
      setErrorMsg('Failed to send contact message: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact">
      <section className="contact-hero"></section>
      <div className="container">
        <div className="contact-form">
          <h2>Drop us a line!</h2>
          {errorMsg && <div className="error-message">{errorMsg}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="Name">Name*</label>
              <input type="text" name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Email*</label>
              <input type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">Phone</label>
              <input type="text" name="Phone" value={formData.Phone} onChange={handleChange} placeholder="Phone" />
            </div>
            <div className="form-group">
              <label htmlFor="Company">Company</label>
              <input type="text" name="Company" value={formData.Company} onChange={handleChange} placeholder="Company" />
            </div>
            <div className="form-group">
              <label htmlFor="Position">Position</label>
              <input type="text" name="Position" value={formData.Position} onChange={handleChange} placeholder="Position" />
            </div>
            <div className="form-group">
              <label htmlFor="Subject">Subject*</label>
              <input type="text" name="Subject" value={formData.Subject} onChange={handleChange} placeholder="Subject" required />
            </div>
            <div className="form-group">
              <label htmlFor="Message">Message*</label>
              <textarea name="Message" value={formData.Message} onChange={handleChange} placeholder="Message" required></textarea>
            </div>
            <button type="submit" className="contact_button" disabled={loading}>
              {loading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Contact() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ld8hIwqAAAAAHGXgiO_J0-yFRvk-2gqPPe2swgm">
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
}
