import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/PartFamSubFamTypes.css";

const PartFamSubFamTypes = ({ refreshTrigger, onEdit }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  // שליפת כל הרשומות מהטבלה
  const fetchRecords = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/partsfamtypes`);
      setRecords(response.data || []);
    } catch (error) {
      console.error("Error fetching PartFamSubFamTypes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [refreshTrigger]);

  // מחיקת רשומה
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this record?")) return;
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/partsfamtypes/${id}`);
      setRecords((prev) => prev.filter((r) => r.id !== id));
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  // עדכון שדה Active (toggle)
  const handleToggleActive = async (row) => {
    const updatedActive = row.Active ? 0 : 1;
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/partsfamtypes/${row.id}`, {
        Active: updatedActive,
      });
      setRecords((prev) =>
        prev.map((r) => (r.id === row.id ? { ...r, Active: updatedActive } : r))
      );
    } catch (error) {
      console.error("Error toggling Active:", error);
    }
  };

  // עדכון שדה deleted (toggle)
  const handleToggleDeleted = async (row) => {
    const updatedDeleted = row.deleted ? 0 : 1;
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/partsfamtypes/${row.id}`, {
        deleted: updatedDeleted,
      });
      setRecords((prev) =>
        prev.map((r) => (r.id === row.id ? { ...r, deleted: updatedDeleted } : r))
      );
    } catch (error) {
      console.error("Error toggling deleted:", error);
    }
  };

  if (loading) return <p>Loading table...</p>;

  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>PartType id</th>
            <th>PartType</th>
            <th>FamType id</th>
            <th>FamType</th>
            <th>SubFamType id</th>
            <th>SubFamType</th>
            <th>Active</th>
            <th>deleted</th>
            <th>updated</th>
            <th>user</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {records.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.PartType_id}</td>
              <td>{row.PartType}</td>
              <td>{row.FamType_id}</td>
              <td>{row.FamType}</td>
              <td>{row.SubFamType_id}</td>
              <td>{row.SubFamType}</td>
              <td>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={!!row.Active}
                    onChange={() => handleToggleActive(row)}
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={!!row.deleted}
                    onChange={() => handleToggleDeleted(row)}
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td>{row.updated || ""}</td>
              <td>{row.user_id || ""}</td>
              <td>
                {/* <button
                  style={{ marginRight: "8px" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </button> */}
                {/* כפתור עדכון */}
                <button onClick={() => onEdit(row)}>
                  Update
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartFamSubFamTypes;
