import React, { useEffect } from "react";

const CompContact = ({ companyId }) => {
  useEffect(() => {
    // ניתן להשתמש ב-companyId כדי לטעון נתונים מהשרת
    console.log("Company ID:", companyId);
    // לבצע בקשת נתונים מבוססת על ה-companyId
  }, [companyId]);

  return (
    <div>
      <h2>Contact Us</h2>
      <p>This will show Contact Us link and if it is Manufacturer – supply chain – suppliers, Distributors, Rep’s etc. link to website.
      My eDMAC will have a list as well with contacts according to data from Vendor</p>
      
    </div>
  );
};

  export default CompContact;