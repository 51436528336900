import React, { useState, useEffect } from "react";
import axios from "axios";
import PartFamSubFamTypes from "./PartFamSubFamTypes";
import "./css/ParentPartFamSubFamTypes.css";

const ParentPartFamSubFamTypes = () => {
  // Select data
  const [partTypeOptions, setPartTypeOptions] = useState([]);
  const [famTypeOptions, setFamTypeOptions] = useState([]);
  const [subFamTypeOptions, setSubFamTypeOptions] = useState([]);

  // ערכים של הרשומה החדשה/לעדכון
  const [partTypeId, setPartTypeId] = useState(null);
  const [partTypeVal, setPartTypeVal] = useState("");

  const [famTypeId, setFamTypeId] = useState(null);
  const [famTypeVal, setFamTypeVal] = useState("");

  const [subFamTypeId, setSubFamTypeId] = useState(null);
  const [subFamTypeVal, setSubFamTypeVal] = useState("");

  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);
  const [updated, setUpdated] = useState(
    new Date().toISOString().split("T")[0] // YYYY-MM-DD
  );
  const [userId, setUserId] = useState(1);

  // טריגר לרענון טבלת הבן
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // מצבי עריכה
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingId, setEditingId] = useState(null);

  // -- 1. טעינת רשימות ה-Select --
  useEffect(() => {
    const fetchSelectData = async () => {
      try {
        // חשוב: כדי לראות גם פריטים עם deleted=1, 
        // צריך שה-API יחזיר אותם (בלי WHERE deleted=0)
        const pRes = await axios.get(`${process.env.REACT_APP_API_URL}/part-types-simple`);
        setPartTypeOptions(pRes.data || []);

        const fRes = await axios.get(`${process.env.REACT_APP_API_URL}/fam-types-simple`);
        setFamTypeOptions(fRes.data || []);

        const sRes = await axios.get(`${process.env.REACT_APP_API_URL}/sub-fam-types-simple`);
        setSubFamTypeOptions(sRes.data || []);
      } catch (error) {
        console.error("Error fetching select data:", error);
      }
    };
    fetchSelectData();
  }, []);

  // -- 2. שינוי select עבור partType --
  const handleChangePartType = (id) => {
    const selected = partTypeOptions.find((opt) => opt.id === parseInt(id, 10));
    setPartTypeId(selected?.id || null);
    setPartTypeVal(selected?.val || "");
  };

  // -- 3. שינוי select עבור famType --
  const handleChangeFamType = (id) => {
    const selected = famTypeOptions.find((opt) => opt.id === parseInt(id, 10));
    setFamTypeId(selected?.id || null);
    setFamTypeVal(selected?.val || "");
  };

  // -- 4. שינוי select עבור subFamType --
  const handleChangeSubFamType = (id) => {
    const selected = subFamTypeOptions.find((opt) => opt.id === parseInt(id, 10));
    setSubFamTypeId(selected?.id || null);
    setSubFamTypeVal(selected?.val || "");
  };

  // -- פונקציית עריכה שתיקרא מה-child --
  const handleEdit = (rowData) => {
    setPartTypeId(rowData.PartType_id);
    setPartTypeVal(rowData.PartType);
    setFamTypeId(rowData.FamType_id || null);
    setFamTypeVal(rowData.FamType || "");
    setSubFamTypeId(rowData.SubFamType_id || null);
    setSubFamTypeVal(rowData.SubFamType || "");
    setActive(rowData.Active || 0);
    setDeleted(rowData.deleted || 0);
    setUpdated(rowData.updated || new Date().toISOString().split("T")[0]);
    setUserId(rowData.user_id || 1);
    setEditingId(rowData.id);
    setIsEditMode(true);
  };

  // -- פונקציה לאיפוס השדות --
  const resetForm = () => {
    setPartTypeId(null);
    setPartTypeVal("");
    setFamTypeId(null);
    setFamTypeVal("");
    setSubFamTypeId(null);
    setSubFamTypeVal("");
    setActive(1);
    setDeleted(0);
    setUpdated(new Date().toISOString().split("T")[0]);
    setUserId(1);
    setIsEditMode(false);
    setEditingId(null);
  };

  // -- 5. הוספה/עדכון רשומה --
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // לדוגמה: נניח ש־PartType הוא חובה, אבל FamType/SubFamType לא חובה
      if (!partTypeId) {
        alert("Please select at least a PartType");
        return;
      }

      const dataToSend = {
        PartType_id: partTypeId,
        PartType: partTypeVal,
        FamType_id: famTypeId,
        FamType: famTypeVal,
        SubFamType_id: subFamTypeId,
        SubFamType: subFamTypeVal,
        Active: active,
        deleted: deleted,
        updated: updated,
        user_id: userId,
      };

      if (isEditMode && editingId) {
        // מצב עדכון
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/partsfamtypes/${editingId}`,
          dataToSend
        );
        console.log("Record updated:", response.data);
        alert("Record updated successfully!");
      } else {
        // מצב הוספה
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/partsfamtypes`,
          dataToSend
        );
        console.log("Record inserted:", response.data);
        alert("Record inserted successfully!");
      }

      // ריענון טבלת הבן
      setRefreshTrigger((prev) => prev + 1);

      // איפוס הטופס
      resetForm();

    } catch (error) {
      console.error("Error in submit record:", error);
      alert("Failed to submit record");
    }
  };

  return (
    <div className="parent-container">
      <h2>Part Types to Fam Types To SubFam Types</h2>
      <form onSubmit={handleSubmit}>
        {/* Select עבור PartType */}
        <div className="form-row">
          <label htmlFor="partTypeSelect">Part Type Select:</label>
          <select
            id="partTypeSelect"
            value={partTypeId || ""}
            onChange={(e) => handleChangePartType(e.target.value)}
          >
            <option value="">Select Part Type</option>
            {partTypeOptions.map((opt) => (
              <option
                key={opt.id}
                value={opt.id}
                style={{ color: opt.deleted ? "gray" : "inherit" }}
                disabled={opt.deleted === 1}
              >
                {opt.val}
              </option>
            ))}
          </select>
          <input
            type="text"
            readOnly
            value={partTypeVal}
            className="value-display"
          />
        </div>

        {/* Select עבור FamType */}
        <div className="form-row">
          <label htmlFor="famTypeSelect">Fam Type Select:</label>
          <select
            id="famTypeSelect"
            value={famTypeId || ""}
            onChange={(e) => handleChangeFamType(e.target.value)}
          >
            <option value="">Select Fam Type (Optional)</option>
            {famTypeOptions.map((opt) => (
              <option
                key={opt.id}
                value={opt.id}
                style={{ color: opt.deleted ? "gray" : "inherit" }}
                disabled={opt.deleted === 1}
              >
                {opt.val}
              </option>
            ))}
          </select>
          <input
            type="text"
            readOnly
            value={famTypeVal}
            className="value-display"
          />
        </div>

        {/* Select עבור SubFamType */}
        <div className="form-row">
          <label htmlFor="subFamTypeSelect">SubFam Type Select:</label>
          <select
            id="subFamTypeSelect"
            value={subFamTypeId || ""}
            onChange={(e) => handleChangeSubFamType(e.target.value)}
          >
            <option value="">Select SubFamType (Optional)</option>
            {subFamTypeOptions.map((opt) => (
              <option
                key={opt.id}
                value={opt.id}
                style={{ color: opt.deleted ? "gray" : "inherit" }}
                disabled={opt.deleted === 1}
              >
                {opt.val}
              </option>
            ))}
          </select>
          <input
            type="text"
            readOnly
            value={subFamTypeVal}
            className="value-display"
          />
        </div>

        {/* Active כ־Toggle */}
        <div className="form-row">
          <label>Active:</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={Boolean(active)}
              onChange={() => setActive(active ? 0 : 1)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {/* deleted כ־Toggle */}
        <div className="form-row">
          <label>deleted:</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={Boolean(deleted)}
              onChange={() => setDeleted(deleted ? 0 : 1)}
            />
            <span className="slider round"></span>
          </label>
        </div>

        {/* updated כתאריך לקריאה בלבד */}
        <div className="form-row">
          <label>updated (YYYY-MM-DD):</label>
          <input
            type="date"
            value={updated}
            disabled
          />
        </div>

        <div className="form-row">
          <label>user [id]:</label>
          <input
            type="number"
            style={{ maxWidth: "50px"}}
            value={userId}
            onChange={(e) => setUserId(Number(e.target.value))}
          />
        </div>

        {/* כפתור משתנה בין Insert ל־Update */}
        <div className="form-actions">
          <button type="submit" className="submit-button">
            {isEditMode ? "עדכן" : "הכנס"}
          </button>
        </div>
      </form>

      <hr />

      <PartFamSubFamTypes
        refreshTrigger={refreshTrigger}
        onEdit={handleEdit}
      />
    </div>
  );
};

export default ParentPartFamSubFamTypes;
