import React, { useState, useEffect } from "react";
import axios from "axios";
const CompBrands = ({ companyId, onBrandsLoaded }) => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}/brands`)
      .then(response => {
        setBrands(response.data);
        setLoading(false);
        onBrandsLoaded(response.data.length > 0);
      })
      .catch(error => {
        console.error("Error fetching brands:", error);
        setLoading(false);
        onBrandsLoaded(false);
      });
  }, [companyId, onBrandsLoaded]);

  if (loading) return <p>Loading...</p>;
  if (!brands.length) return null;

  return (
    <div>
      <h2>Brand</h2>
      <ul>
        {brands.map(brand => <li key={brand.id}>{brand.name}</li>)}
      </ul>
      
    </div>
  );
};


export default CompBrands;
