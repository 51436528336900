import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link as ScrollLink, Element } from "react-scroll";
import axios from "axios";
import About from "./Companies/CompAbout";
import Products from "./Companies/CompProducts";
import Brands from "./Companies/CompBrands";
import ContactUs from "./Companies/CompContact";
import CompanyHeader from "./Companies/CompanyHeader";
import DistributorManufacturer from "./Companies/CompDistributorManufacturer";
import "./css/Companies.css"; // Ensure this path is correct

const Companies = () => {
  const { id: companyId } = useParams();
  const [showBrands, setShowBrands] = useState(false);
  const [companyType, setCompanyType] = useState({
    name: "Loading...",
    type: "",
    website: "#",
    ECIA_Member: "",
  });
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${companyId}`
        );
        console.log("Company details fetched successfully:", response.data);
        let companyType = "Unknown"; // Default value if no conditions are met
        switch (response.data.company_type_id) {
          case 1:
            companyType = "Manufacturer";
            break;
          case 3:
            companyType = "Supplier";
            break;
          case 4:
            companyType = "Subcontractor";
            break;
          case 6:
            companyType = "Component Search Company";
            break;
          default:
            companyType = "Unknown";
        }
        setCompanyType(companyType);
      } catch (error) {
        console.error("Failed to fetch company details:", error);
        setCompanyType("Failed to load");
      }
    };

    fetchCompany();
  }, [companyId]);

  return (
    <div className="companies-page">
      <CompanyHeader companyId={companyId} />
      <nav className="sub-nav">
        <ul>
          <li>
            <ScrollLink to="about" smooth={true} duration={500} offset={-60}>
              About
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to="products" smooth={true} duration={500} offset={-60}>
              Products
            </ScrollLink>
          </li>
          {showBrands && (
            <li>
              <ScrollLink to="brands" smooth={true} duration={500} offset={-60}>
                Brands
              </ScrollLink>
            </li>
          )}
          <li>
            <ScrollLink to="contact" smooth={true} duration={500} offset={-60}>
              Contact Us
            </ScrollLink>
          </li>
        </ul>
      </nav>
      <Element name="about" className="section">
        <div className="comp-section-gray">
          <About companyId={companyId} />
        </div>
      </Element>
      <Element name="products" className="section">
        <div className="comp-section-white">
          <Products companyId={companyId} />
        </div>
      </Element>
      {showBrands && (
        <Element name="brands" className="section">
          <div className="comp-section-gray">
            <Brands companyId={companyId} onBrandsLoaded={setShowBrands} />
          </div>
        </Element>
      )}
      <Element name="contact" className="section">
        <div className="comp-section-gray">
          <DistributorManufacturer
            companyId={companyId}
            companyType={companyType}
          />
        </div>
      </Element>
      <Element name="contact" className="section">
        <div className="comp-section-white">
          <ContactUs companyId={companyId} />
        </div>
      </Element>
    </div>
  );
};

export default Companies;
