import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Vendors from "./pages/Vendors";
import Contact from "./pages/Contact";
import Companies from "./components/Companies";
import AdvancedSearch from "./pages/Search";
import ParentPartFamSubFamTypes from "./components/ParentPartFamSubFamTypes";
//import Chatbot from "./components/Chatbot";
import Logo from "./assets/Video/logo.gif";
import TermsGlossary from "./pages/Glossary";
//import Test from './pages/Test';
import "./App.css";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  
  return (
    <Router>
      <div className="wrapper">
        <div className="App">
          <header>
            <nav className="navbar">
              <div className="container">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} height="65px" alt="Logo" />
                  </Link>
                </div>
                <ul className="nav-links">
                  <li>
                    <i className="fa-solid fa-house"></i>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fa-solid fa-passport"></i>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <i className="fa-solid fa-hand-holding-heart"></i>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <i className="fa-solid fa-grip-vertical"></i>
                    <Link to="/vendors">Vendors</Link>
                  </li>
                  <li>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <Link to="/search">Component Search</Link>
                  </li>
                  <li>
                    <i className="fa-regular fa-rectangle-list"></i>
                    <Link to="/glossary">Glossary</Link>
                  </li>
                  <li>
                    <i className="fa-regular fa-address-card"></i>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
                <div className="hamburger" onClick={toggleMenu}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </div>
              <ul className={`nav-links-mobile ${isOpen ? "open" : ""}`}>
                <li>
                  <Link to="/" onClick={toggleMenu}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={toggleMenu}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" onClick={toggleMenu}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/vendors" onClick={toggleMenu}>
                    Vendors
                  </Link>
                </li>
                <li>
                  <Link to="/search" onClick={toggleMenu}>
                  ComponentSearch
                  </Link>
                </li>
                <li>
                  <Link to="/glossary" onClick={toggleMenu}>
                    Glossary
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toggleMenu}>
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </header>
          <main className="main-content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/vendors" element={<Vendors />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/glossary" element={<TermsGlossary />} />
              <Route path="/companies/:id" element={<Companies />} />
              <Route path="/search" element={<AdvancedSearch />} />
              <Route path="/ppff" element={<ParentPartFamSubFamTypes />} />
            </Routes>
          </main>
          {showScrollButton && (
          <button onClick={scrollToTop} className="scroll-top-btn">
            Scroll Up
          </button>
        )}
          <footer>
            <div className="footer-content">
              <div className="footer-section">
                <i className="fas fa-copyright"></i>
                <p>2024 My eDMAC. All Rights Reserved.</p>
              </div>
              <div>
                <i className="fas fa-phone"></i> +972-77-4811452
              </div>
              <div>
                 <i className="fas fa-envelope"></i>
                 <a href="mailto:contactus@myedmac.com" style={{ textDecoration: "none", color: "#ffffff" }} rel="noreferrer" title="Drop us an email">  
                  contactus@myedmac.com
                </a>
              </div>
              <div className="footer-link">
                <i className="fab fa-linkedin"></i>
                <a
                  href="https://www.linkedin.com/in/yossy-moran-546b392/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;



